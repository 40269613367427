import store from "@/store";
import table from "@/data/table";
/**
 * @description 历史记录的table，根据不同tab来区分
 * @param  tab NB SH QD
 * @param type 1.当前的store中的历史记录列表的值 2. 修改当前的store中的历史记录列表的值 3. 原始列表
 * @param data 修改store中的历史记录列表的值
 */
export const historyTableList = ({ tab, type, data }) => {
    switch (type) {
        case 1:
            return store.state.table.historyList;
        case 2:
            return store.commit("table/resetHistoryList", data);
        case 3:
            return table.resetTableNB;
    }
    // 暂时注释掉,上海和青岛的历史暂时不做
    // switch (tab) {
    //     case "NB":
    //         switch (type) {
    //             case 1:
    //                 return store.state.table.historyList;
    //             case 2:
    //                 return store.commit("table/resetHistoryList", data);
    //             case 3:
    //                 return table.resetTableNB
    //         }
    //     case "SH":
    //         switch (type) {
    //             case 1:
    //                 return store.state.table.historyListSH;
    //             case 2:
    //                 return store.commit("table/resetHistoryListSH", data);
    //             case 3:
    //                 return table.sResetTable
    //         }
    //     case "QD":
    //         switch (type) {
    //             case 1:
    //                 return store.state.table.historyListQD;
    //             case 2:
    //                 return store.commit("table/resetHistoryListQD", data);
    //             case 3:
    //                 return table.qdResetTable
    //         }
    // }
};
