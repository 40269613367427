<template>
    <ShipDialog
        :visible.sync="dialogTableVisible"
        title="确定要还原吗？"
        showTab="NB"
        size="small"
        width="500px"
    >
        <div class="ticket_div">
            <p>
                将还原
                <em>{{ list.length }}</em>
                单
            </p>
            <el-table
                border
                stripe
                :data="list"
                style="width: 100%"
                :max-height="250"
                :default-sort="{ prop: 'date', order: 'descending' }"
                :header-cell-style="{
                    background: '#eef1f6',
                    color: '#606266',
                }"
            >
                <el-table-column prop="blno" label="提单号"></el-table-column>
                <el-table-column label="船名/航次" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <p class="cell_p">
                            {{ scope.row.vesselsName }}/{{ scope.row.voyage }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <DialogFooter
            @confirm="recallDeleted"
            @cancel="closeDialog"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import { logisticsRequest } from "../../Logistics/logistics";
import { orderBoxTicket } from "@/api/tracking";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter";
export default {
    components: {
        ShipDialog,
        DialogFooter,
    },
    data() {
        return {
            test: "测试信息",
            dialogTableVisible: false,
            list: [],
            submitLoading: false,
        };
    },
    mounted() {},
    methods: {
        init(list) {
            this.dialogTableVisible = true;
            this.list = list;
        },
        async recallDeleted() {
            this.submitLoading = true;
            try {
                const code = this.$store.state.other.systemKey;
                const param = this.list.map((item) => ({
                    ...item,
                    code,
                }));
                // 还原调用的拼票的接口
                const url = logisticsRequest({
                    tab: "NB",
                    type: 5,
                });
                // console.log(url,'url')
                await orderBoxTicket(url, param);
                this.$message.success("还原成功");
                this.$emit("refreshDataList");
            } finally {
                this.submitLoading = false;
                this.closeDialog();
            }
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
    },
};
</script>
<style lang="stylus" scoped>
.ticket_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-image {
  width: 30rem;
  height: 30rem;
}

em{
  color: var(--RED-F21137);
    font-size: 2rem;
}
.cell_p {
  text-align: center;
  margin: 0;
}
</style>
